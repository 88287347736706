import React from "react";
import PageContent from "../PageContent";
import SEO from "../SEO";
import { emojiCursor } from "../EmojiCursor";

import "../../styles/global.scss";

function sayHello() {
  console.log(
    `
        %c🤠%c

        ${new Date()}
        Design by Midas Kwant & Code by Daan van der Zwaag

        `,
    "font-size: 80px; line-height: 1;",
    "font-size: 12px; font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif; font-weight: 600; line-height: 1.25;"
  );
}

class Layout extends React.Component {
  componentDidMount() {
    new emojiCursor({ emoji: ["✨", "🤠", "🔥", "💰", "🤩", "👀"] });
    sayHello();
  }

  render() {
    return (
      <>
        <SEO title={this.props.pageName} />
        <PageContent pageName={this.props.pageName}>
          {this.props.children}
        </PageContent>
      </>
    );
  }
}

export default Layout;
